.underline {
  grid-column-start: 0;
  grid-column-end: 0;
  width: 70vw;
  height: calc(var(--header-height) / 1.7);
  opacity: 0.75;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.underline-left {
  height: 100%;
  width: auto;
}
.underline-mid {
  height: calc(var(--header-height));
  flex-grow: 1;
  height: 100%;
}
.underline-right {
  transform: scaleX(-1);
  height: 100%;
  width: auto;
}
